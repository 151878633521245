import { useState } from "react"

import { Flex, Text, Input, Button, Box, Stack, Hide, HStack, Show, VStack, Image } from "@chakra-ui/react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Link from "next/link"

import { CloudImages } from "lib/cosmic/images"
import { validateEmail as validateEmailHelper } from "lib/emails"

import { SocialIcon } from "./SocialIcon"
import Logo from "./Logo"

const NavLinks = () => {
  return (
    <Flex>
      <SocialIcon link="https://twitter.com/integralprivacy" mr="16px">
        <Image src={CloudImages.TWITTER_ICON} alt="Twitter" />
      </SocialIcon>

      <SocialIcon link="https://www.linkedin.com/company/integralhealthcare/">
        <Image src={CloudImages.LINKEDIN_ICON} alt="LinkedIn" />
      </SocialIcon>
    </Flex>
  )
}

const SubscribeCTA = () => {
  const [submitted, setSubmitted] = useState(false)

  const validateEmail = (value: string) => {
    setSubmitted(false)

    if (!value) {
      return "Email is required"
    }

    if (!validateEmailHelper(value)) {
      return "Must be a valid email address"
    }

    return null
  }

  return (
    <VStack borderRadius="10px" flexDirection="column" alignItems="flex-start" id="subscribe" width={["100%", "inherit"]} maxWidth={["500px", "inherit"]}>
      <Text color="white" marginBottom="12px">
        Subscribe to our newsletter
      </Text>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          // Here you can write your code to handle the form submission
          // After handling the form submission, you can reset the form
          resetForm()
          setSubmitting(false)
          setSubmitted(true)
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: "100%" }}>
            <Field
              as={Input}
              placeholder="Enter your email"
              color="white"
              borderRadius="50px"
              border="1px solid var(--website-color-border-dark)"
              name="email"
              display="block"
              validate={validateEmail}
              width="100%"
              marginBottom="16px"
            />
            <Button
              color="white"
              bg="var(--website-color-highlight)"
              borderRadius="50px"
              _hover={{
                bg: "var(--website-color-highlight)",
              }}
              width="100%"
              type="submit"
              disabled={isSubmitting}
            >
              Subscribe
            </Button>
            <ErrorMessage name="email" component="div">
              {(msg) => (
                <Text paddingTop="12px" color="red">
                  {msg}
                </Text>
              )}
            </ErrorMessage>
            {submitted && (
              <Text color="var(--website-color-special-feature)" fontStyle="italic" textAlign="left" marginTop="24px">
                Thank You for Subscribing!
              </Text>
            )}
          </Form>
        )}
      </Formik>
    </VStack>
  )
}

const sections = [
  {
    title: "About Integral",
    links: [
      { name: "How It Works", href: "/how-it-works" },
      { name: "Why Us", href: "/why-us" },
      { name: "Use Cases", href: "/customer-stories" },
    ],
  },
  {
    title: "Resources",
    links: [
      { name: "Blog", href: "/blog" },
      { name: "Press", href: "/press" },
      { name: "Terms of Service", href: "/terms-of-service" },
      { name: "Privacy Policy", href: "/privacy-policy" },
    ],
  },
]

const Footer = () => {
  return (
    <Flex
      backgroundColor="#"
      background="linear-gradient(to bottom right, #0B0825, #130e3f)"
      py="32px"
      paddingTop={["32px", "32px", "20px"]}
      paddingBottom={["16px", "16px", "36px"]}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box margin="auto" width="90%" px={[5, 0, 0]}>
        <Stack
          maxWidth="var(--website-width-max)"
          margin="auto"
          mt="16px"
          justify="space-between"
          align="top"
          direction={["column-reverse", "column-reverse", "row"]}
          spacing={["32px", "32px", "0px"]}
        >
          <HStack justifyContent="space-between" align="top">
            <Logo />
            <Show below="md">
              <NavLinks />
            </Show>
          </HStack>
          <Hide below="md">
            <Box>
              <HStack spacing="100px" alignItems="top">
                {sections.map((section) => (
                  <VStack alignItems="left" key={section.title}>
                    <Text color="white" marginBottom="8px">
                      {section.title}
                    </Text>
                    {section.links.map((link) => (
                      <Link href={link.href} key={link.name}>
                        <Text color="#9296AD">{link.name}</Text>
                      </Link>
                    ))}
                  </VStack>
                ))}
              </HStack>
            </Box>
          </Hide>

          <Hide below="md">
            <Box>
              <Box marginBottom="40px">
                <Link href="/contact-us">
                  <Text color="white">Contact Us</Text>
                </Link>
              </Box>
              <Text color="#fff" marginBottom="16px">
                Follow Us
              </Text>
              <NavLinks />
            </Box>
          </Hide>

          <SubscribeCTA />
        </Stack>
        <Text
          color="#9296AD"
          margin="auto"
          textAlign={["center", "center", "left"]}
          fontSize="16px"
          mt={["24px", "24px", "48px"]}
          maxWidth="var(--website-width-max)"
          borderTop="1px solid var(--website-color-hr)"
          paddingTop="12px"
        >
          Copyright © Integral
        </Text>
      </Box>
    </Flex>
  )
}

export default Footer
